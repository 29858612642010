import { uploader } from "../helpers/uploader";

export default class Documents {
  constructor(el) {
    this.el = el;
    this.form = this.el.querySelector('.js-document-form');
    this.uploader = this.form.querySelector('.js-documents-upload');
    this.label = this.form.querySelector('.js-document-filename');

    uploader(this.form, this.uploader, 'document', this.fileNameToLabel);
  }

  fileNameToLabel = () => {
    this.label.textContent = this.uploader.files[0].name;
  }
}