import {Modal} from '../helpers/modal';
import {showFlash} from '../helpers/showFlash';

export default class NumberItem {
  constructor(el) {
    this.el = el;
    this.number = this.el.querySelector('.js-user-number').value;
    this.callbackBtn = this.el.querySelector('.js-callback-btn');
    this.callModal = this.el.querySelector('.js-call-modal');
    this.pcCallBtn = this.el.querySelector('.js-pc-call-btn');
    this.selectedCountry = this.el.querySelector('.js-country-select');
    this.callModalBtn = this.el.querySelector('.js-call-modal-btn');
    this.callCancelBtn = this.el.querySelector('.js-call-cancel-btn');
    this.callModalError = this.el.querySelector('.js-call-modal-error');
    this.callModalInput = this.el.querySelector('.js-call-modal-input');
    this.callDialingNumber = this.el.querySelector('.js-dialing-number');
    this.callingStatus = this.el.querySelector('.js-calling-status');
    this.activeCallStatus = this.el.querySelector('.js-call-status');
    this.smsBtn = this.el.querySelector('.js-sms-btn');
    this.addDestinationBtn = this.el.querySelector('.js-add-destination-btn');
    this.delItemBtn = this.el.querySelector('.js-del-did');
    this.modalForm = this.el.querySelector('.call-modal__form');
    this.incomingCall = this.el.querySelector('.js-incoming-call');
    this.incomingStatus = this.el.querySelector('.js-incoming-status');
    this.incomingNumber = this.el.querySelector('.js-incoming-number');
    this.incomingCallCancelBtn = this.el.querySelector('.js-incoming-call-cancel-btn');
    this.incomingCallBtns = this.el.querySelector('.js-incoming-call-buttons');
    this.incomingCallBtn = this.el.querySelector('.js-incoming-call-modal-btn');
    this.rejectIncomingCallBtn = this.el.querySelector('.js-reject-incoming-call-modal-btn');
    this.keypad = this.el.querySelector('.js-keypad');
    this.keypadButtons = this.el.querySelectorAll('.keypad-button');
    this.addAliasLink = this.el.querySelector('.js-add-alias');
    this.aliasForm = this.el.querySelector('.js-alias-form');
    this.aliasName = this.el.querySelector('.js-alias-name');

    this.destinationList = this.el.querySelector('.js-destination-list');
    this.delBtns = this.destinationList.querySelectorAll("a[data-remote]");
    this.el.setAttribute('data-id', this.number);
    this.did = this.el.getAttribute('data-did');
    this.hasCredit = this.el.getAttribute('data-has-credit');
    this.callTokenData = JSON.parse(this.el.getAttribute('data-call-token') ?? '{}');
    this.callProvider = this.el.getAttribute('data-provider');
    this.device = null
    this.currentCall = null;
    this.plivoCall = null;
    if (parseFloat(this.hasCredit) > 0) {
      this.initializeCall();
      this.eventHandler();
    }
  }

  eventHandler = () => {
    this.callbackBtn.addEventListener('ajax:success', () => this.openModal('.js-modal-callback'));
    this.smsBtn.addEventListener('ajax:success', () => this.openModal('.js-modal-sms'));
    this.addDestinationBtn.addEventListener('ajax:success', () => this.openModal('.js-modal-destination'));
    this.delBtns.forEach(item => item.addEventListener("ajax:success", () => this.removeDestination(item)));
    this.destinationList.addEventListener("DOMNodeInserted", () => this.getDelLinks());
    this.delItemBtn.addEventListener('ajax:success', () => this.el.remove());
    this.pcCallBtn?.addEventListener('click', () => {
      if (this.callModal.classList.contains('js-hide')) {
        this.openCallModal();
      } else {
        this.closeCallModal();
      }
    });
    this.callModalBtn?.addEventListener('click', (e) => this.initiateCall(e));
    this.callCancelBtn?.addEventListener('click', (e) => this.callCancel(e));
    this.incomingCallCancelBtn?.addEventListener('click', (e) => this.callCancel(e));
    this.callModalInput?.addEventListener('input', () => this.inputText());
    this.keypadButtons.forEach(button => {
      button.addEventListener('click', this.handleKeypadPress);
    });
    this.addAliasLink?.addEventListener('click', () => this.aliasFormDisplay());
    this.aliasName?.addEventListener('click', () => this.aliasFormDisplay());
  }

  openModal = (selector) => {
    const modal = document.querySelector(`${selector}`);
    const newModal = new Modal(modal);
    newModal.open();
    this.modalForm = modal.querySelector('.modal__form');
    this.flashMsg = document.querySelector('.dynamic-flash');
    this.modalForm.addEventListener('ajax:success',(data = null) => {
      const response = data.detail[0];
      if (response?.message){
        showFlash(this.flashMsg, response?.message);
      }
    });
    this.modalForm.addEventListener('ajax:error',(data = null) => {
      newModal.close();
      showFlash(this.flashMsg, 'Server Error');
    });
    this.uploadedImage = modal.querySelector('.image-upload');
    this.uploadedImageName = modal.querySelector('.image-name');
    this.uploadedImage?.addEventListener('change', (evt) => {
      this.uploadedImageName.innerHTML = this.uploadedImage.files[0].name
      this.delImage.style.display = "flex";
    });
    this.delImage = modal.querySelectorAll('.js-del-image')[0];
    this.delImage?.addEventListener('click', () => {
      this.uploadedImageName.innerHTML = '';
      this.delImage.style.display = "none";
    });
  }

  removeDestination = (item) => {
    const parentItem = item.closest('.js-destination-item');
    parentItem.remove();
  }

  getDelLinks = () => {
    this.delBtns = this.destinationList.querySelectorAll("a[data-remote]");
    this.delBtns.forEach(item => item.addEventListener("ajax:success", () => this.removeDestination(item)));
  }

  openCallModal = () => {
    this.callModal.classList.remove('js-hide');
  }

  closeCallModal = () => {
    this.callModal.classList.add('js-hide');
  }

  initializeCall() {
    if (this.callTokenData['token']) {
      if (this.callProvider == 'Plivo') {
        this.initPlivoCall();
      } else if (this.callProvider == 'Twilio') {
        this.initTwilioCall();
      }
    }
  }

  async initiateCall(e) {
    const inputVal = this.callModalInput.value;
    if (!inputVal) {
      this.callModalError.textContent = 'Please fill out this field';
      setTimeout(() => {
        this.callModalError.textContent = '';
      }, 5000);
      e.prevetDefault();
      return;
    }

    const selectedOption = this.selectedCountry.options[this.selectedCountry.selectedIndex].value;
    const dialingNumber = '+' + selectedOption + inputVal
    this.callDialingNumber.innerHTML = dialingNumber

    if (this.callProvider === 'Plivo') {
      this.plivoCall = this.plivoBrowserSdk.client.call(dialingNumber);
    } else if (this.callProvider === 'Twilio') {
      const params = {
        To: dialingNumber,
        Agent: this.did,
        From: this.did,
        Caller: this.did
      };
      this.device = new Twilio.Device(this.callTokenData['token'], {
        codecPreferences: ["opus", "pcmu"],
        enableRingingState: true,
        logLevel: process.env.NODE_ENV === 'development' ? 1 : 4
      });

      this.currentCall = await this.device.connect({ params });
      this.handleTwilioEvents(this.currentCall);
    }

    this.callModalBtn.style.display = 'none';
    this.activeCallStatus.style.display = 'block';
  }

  initPlivoCall() {
    const plivoUsername = this.callTokenData.token.username;
    const plivoPass = this.callTokenData.token.password;
    const options = {
      "debug": process.env.NODE_ENV === 'development' ? "DEBUG" : "ERROR",
      "permOnClick": true,
      "enableTracking": true,
      "closeProtection": true,
      "maxAverageBitrate": 48000
    };

    if (!this.plivoBrowserSdk) {
      this.plivoBrowserSdk = new window.Plivo(options);
    }

    if (this.plivoBrowserSdk.client.isLoggedIn) {
      this.plivoBrowserSdk.client.logout();
    }

    this.plivoBrowserSdk.client.login(plivoUsername, plivoPass);
    this.plivoEvents();

    this.plivoBrowserSdk.client.on('onLogin', () => {});

    this.plivoBrowserSdk.client.on('onIncomingCall', (callerName, extraHeaders) => {
      const callerNumber = callerName.split("@")[0];
      this.plivoCall = true
      this.openCallModal();
      this.displayCallUIHideButton();
      this.incomingCallBtns.style.display = 'flex';
      this.incomingCallCancelBtn.style.display = 'none';
      this.incomingNumber.innerHTML = callerNumber;
      this.incomingStatus.innerHTML = "Ringing...";

      this.incomingCallBtn.addEventListener('click', () => {
        this.plivoBrowserSdk.client.answer();
        this.incomingCallBtns.style.display = 'none';
        this.incomingCallCancelBtn.style.display = 'block';
      });

      this.rejectIncomingCallBtn.addEventListener('click', () => {
        this.plivoBrowserSdk.client.reject();
        this.hideCallUIDisplayButton();
      });
    });
  }

  plivoEvents = () => {
    this.plivoBrowserSdk.client.on('onCallRemoteRinging', (callInfo) => {
      this.callingStatus.innerHTML = "Ringing..."
    });

    this.plivoBrowserSdk.client.on('onCallAnswered', (callInfo) => {
      this.callingStatus.innerHTML = "Connected..."
      this.showKeypad();
    });

    this.plivoBrowserSdk.client.on('onCallTerminated', (hangupInfo, callInfo) => {
      this.callCancel(undefined, false);
    });

    this.plivoBrowserSdk.client.on('onCallFailed', (callInfo) => {
      this.callCancel(undefined, false);
    });

    this.plivoBrowserSdk.client.on('onCallRejected', (callInfo) => {
      this.callCancel(undefined, false);
    });

    this.plivoBrowserSdk.client.on('onCallAnswered', (callInfo) => {
      this.incomingStatus.innerHTML = "Connected...";
    });

    this.plivoBrowserSdk.client.on('onIncomingCallCanceled', (info) => {
      this.hideCallUIDisplayButton();
    });

    this.plivoBrowserSdk.client.on('onCallRejected', (callInfo) => {
      this.incomingStatus.innerHTML = "Call Rejected!";
    });
  }

  async initTwilioCall() {
    const device = new Twilio.Device(this.callTokenData['token'], {
      codecPreferences: ["opus", "pcmu"],
      enableRingingState: true,
      logLevel: process.env.NODE_ENV === 'development' ? 1 : 4
    });

    device.on("registered", () => {});
    device.on("ready", (device) => {});
    device.on("error", (error) => {});
    device.on("connect", (conn) => {});
    device.on("disconnect", (conn) => { this.callCancel(); });

    device.on("incoming", (connection) => {
      this.handleIncomingTwilioCall(connection);
    });

    device.register();
  }

  handleIncomingTwilioCall(connection) {
    const toNumber = connection.parameters.To;
    const did = 'client:' + '+' + this.did;

    if (toNumber !== did) {
      return;
    }

    const from = connection.parameters.From;
    this.currentCall = connection
    this.openCallModal();
    this.displayCallUIHideButton();
    this.incomingCallBtns.style.display = 'flex';
    this.incomingCallCancelBtn.style.display = 'none';
    this.incomingNumber.innerHTML = from;
    this.incomingStatus.innerHTML = "Incoming call...";

    this.incomingCallBtn.addEventListener('click', () => {
      connection.accept();
      this.incomingCallBtns.style.display = 'none';
      this.incomingCallCancelBtn.style.display = 'block';
      this.incomingStatus.innerHTML = "Connected...";
    });

    this.rejectIncomingCallBtn.addEventListener('click', () => {
      connection.reject();
      this.hideCallUIDisplayButton();
      this.incomingStatus.innerHTML = "Call Rejected!";
    });

    connection.on("accept", () => {
      this.incomingStatus.innerHTML = "Connected...";
    });

    connection.on("disconnect", () => {
      this.callCancel();
    });

    connection.on("cancel", () => {
      this.callCancel();
    });
  }

  handleTwilioEvents(call) {
    call.on('accept', (data = undefined) => {
      this.callingStatus.innerHTML = "Connected...";
      this.showKeypad();
    });

    call.on('ringing', (data = undefined) => {
      this.callingStatus.innerHTML = "Ringing...";
    });

    call.on('disconnect', (data = undefined) => {
      this.callCancel();
    });
  }

  inputText = () => {
    this.callModalError.textContent = '';
  }

  callCancel = (event = undefined, hangup = true) => {
    if(event) {
      event.preventDefault();
    }

    if (this.plivoCall && hangup) {
      this.plivoBrowserSdk.client.hangup();
    }
    this.plivoCall = null;

    if (this.currentCall) {
      this.currentCall.disconnect();
      this.currentCall = null;
    }

    this.activeCallStatus.style.display = 'none';
    this.hideKeypad();
    this.hideCallUIDisplayButton();
  }

  handleKeypadPress = (event) => {
    const pressedButton = event.target;
    const pressedDigit = pressedButton.getAttribute('data-digit');

    this.sendDTMF(pressedDigit);
  }

  showKeypad = () => {
    this.keypad.style.display = 'grid';
  }

  hideKeypad = () => {
    this.keypad.style.display = 'none';
  }

  sendDTMF = (digit) => {
    if (this.plivoCall) {
      this.plivoBrowserSdk.client.sendDtmf(digit);
    } else if (this.currentCall) {
      this.currentCall.sendDigits(digit);
    }
  }

  displayCallUIHideButton() {
    this.incomingCall.style.display = 'block';
    this.callModalBtn.style.display = 'none';
  }

  hideCallUIDisplayButton() {
    this.incomingCall.style.display = 'none';
    this.callModalBtn.style.display = 'block';
  }

  aliasFormDisplay() {
    this.aliasForm.style.display = 'flex';
    if (this.addAliasLink) {
      this.addAliasLink.style.display = 'none';
    }

    if (this.aliasName) {
      this.aliasName.style.display = 'none';
    }
  }
}
